import { createSlice } from "@reduxjs/toolkit";
import { ListOfAssessmentsObject } from "stories/vervoeApi/types";
import {
  createAsyncAction,
  FetchError,
} from "@nait-aits/redux";

import {
  CreateUserAssessmentData,
  MyAssessmentsData,
  UserAssessmentsResultData,
} from "./types/userAssessment";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "vervoe";

export type UserAssessmentsStateInfo = {
  isLoading: boolean;
  state?: UserAssessmentsResultData;
  error?: FetchError;
};

export type CreateUserAssessmentStateInfo = {
  isLoading: boolean;
  error?: FetchError;
  state?: CreateUserAssessmentData;
};

export type MyAssessmentsStateInfo = {
  isLoading: boolean;
  error?: FetchError;
  state?: MyAssessmentsData;
};

export type AllAssessmentsStateInfo = {
  isLoading: boolean;
  error?: FetchError;
  state?: ListOfAssessmentsObject;
};

export type State = {
  userAssessments: UserAssessmentsStateInfo;
  createAssessment: CreateUserAssessmentStateInfo;
  myAssessments: MyAssessmentsStateInfo;
  allAssessments: AllAssessmentsStateInfo;
};

var getUserAssessments = createAsyncAction<
  UserAssessmentsResultData,
  { page: number },
  State
>({
  actionPrefix: controlName,
  actionName: "getUserAssessments",
  url: `${process.env.REACT_APP_API_BASE}/Assessment/GetAssessments`,
  transformUrl: (url, params) => {
    return `${url}/${params.page}`;
  },
  pending: (state, action) => {
    state.userAssessments = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.userAssessments.isLoading = false;
    state.userAssessments.state = action.payload;
  },
  rejected: (state, action) => {
    state.userAssessments.isLoading = false;
    state.userAssessments.error = action.payload;
  },
  getAuthBearerToken
});

var createUserAssessment = createAsyncAction<
  CreateUserAssessmentData,
  { assessmentId: string },
  State
>({
  actionPrefix: controlName,
  actionName: "createUserAssessment",
  url: `${process.env.REACT_APP_API_BASE}/Assessment/CreateAssessment`,
  pending: (state, action) => {
    state.createAssessment = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.createAssessment.isLoading = false;
    state.createAssessment.state = action.payload;
  },
  rejected: (state, action) => {
    state.createAssessment.isLoading = false;
    state.createAssessment.error = action.payload;
  },
  getAuthBearerToken
});

var myAssessments = createAsyncAction<
  MyAssessmentsData,
  undefined,
  State
>({
  actionPrefix: controlName,
  actionName: "myAssessments",
  url: `${process.env.REACT_APP_API_BASE}/Assessment/MyAssessments`,
  pending: (state, action) => {
    state.myAssessments = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.myAssessments.isLoading = false;
    state.myAssessments.state = action.payload;
  },
  rejected: (state, action) => {
    state.myAssessments.isLoading = false;
    state.myAssessments.error = action.payload;
  },
  getAuthBearerToken
});

var getListOfAssessments = createAsyncAction<
  ListOfAssessmentsObject,
  undefined,
  State
>({
  actionPrefix: controlName,
  actionName: "getListOfAssessments",
  url: `${process.env.REACT_APP_API_BASE}/Assessment/GetListOfAssessments`,
  pending: (state, action) => {
    state.allAssessments = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.allAssessments.isLoading = false;
    state.allAssessments.state = action.payload;
  },
  rejected: (state, action) => {
    state.allAssessments.isLoading = false;
    state.allAssessments.error = action.payload;
  },
  getAuthBearerToken
});

var slice = createSlice({
  name: controlName,
  initialState: {
    userAssessments: {
      isLoading: false,
    },
    createAssessment: {
      isLoading: false,
    },
    myAssessments: {
      isLoading: false,
    },
    allAssessments: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...getUserAssessments.reducer,
    ...createUserAssessment.reducer,
    ...myAssessments.reducer,
    ...getListOfAssessments.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getUserAssessments: getUserAssessments.action,
      createUserAssessment: createUserAssessment.action,
      myAssessments: myAssessments.action,
      getListOfAssessments: getListOfAssessments.action,
    },
  },
};

import { useHistory } from "react-router";
import RightAppBarContent from "./templates/MainPageTemplate/components/RightAppBarContent";
import MainPageTemplate from "./templates/MainPageTemplate/MainPageTemplate";

export type Props = {};

const allowableCredentials2 = [
  {
    name: "Quality Management Basics",
    slug: "leadership-skills-assessment-11",
  },
  { name: "Quality Design Principles", slug: "reliability-skills-assessment" },
  {
    name: "Quality Evaluation and Monitoring",
    slug: "acceptance-sampling-skills-assessment",
  },
  {
    name: "Quality Management Systems and Auditing",
    slug: "quality-management-systems-skills-assessment",
  },
];

const LandingPage = (props: Props) => {
  const base = new URL("/", window.location.href).href;

  const history = useHistory();

  return (
    <MainPageTemplate
      appBarRightContent={<RightAppBarContent />}
      hideFeatureBar={false}
      responsiveBody={true}
      appBarOnBackClick={()=>history.push("my")}
      body={
        <>
          <div
            style={{ backgroundColor: "white", padding: 20, marginBottom: 20 }}
          >
            <div>
              <div style={{ marginBottom: 40 }}>
                <h2>Email Templates</h2>
                <b>Payment Complete (client email)</b>
                <p>{getUrlLink("temp/email1")}</p>
                <b>Student Paid Email (Gail email)</b>
                <p>{getUrlLink("temp/email5")}</p>
                <b>Assessment Ready for Grading (Assessment Centre email)</b>
                <p>{getUrlLink("temp/email2")}</p>
                <b>Assessment Passed, not entered in CS (client email)</b>
                <p>{getUrlLink("temp/email3")}</p>
                <b>Assessment Failed (client email)</b>
                <p>{getUrlLink("temp/email6")}</p>
                <b>Completion Email (client email)</b>
                <p>{getUrlLink("temp/email4")}</p>
              </div>
              <div style={{ marginBottom: 40 }}>
                <h2>Self Evaluation Landing Page</h2>
                <div>
                  <a
                    href={`${base}selfassessment/qualitymanagement`}
                  >{`${base}selfassessment/qualitymanagement`}</a>
                </div>
              </div>
              <div style={{ marginBottom: 40 }}>
                <h2>Add to Cart Links</h2>
                {allowableCredentials2.map((e) => (
                  <div key={e.slug}>
                    <b>{e.name}</b>
                    <p>
                      <a
                        href={`${base}cart/${e.slug}`}
                      >{`${base}cart/${e.slug}`}</a>
                    </p>
                  </div>
                ))}
              </div>
              {/* <div style={{ marginBottom: 40 }}>
                <h2>Set Assessment as Entered in PeopleSoft</h2>
                <a
                  href={`${process.env.REACT_APP_API_BASE}/AcademicToolsAPI/SetPeopleSoftComplete?clientAssessmentId=-1`}
                  target="_blank"
                  rel="noreferrer"
                >{`${process.env.REACT_APP_API_BASE}/AcademicToolsAPI/SetPeopleSoftComplete?clientAssessmentId=id`}</a>
              </div> */}
            </div>
          </div>
        </>
      }
    />
  );
};

function getUrlLink(page: string) {
  return (
    <a
      href={`${process.env.REACT_APP_API_BASE}/${page}`}
      target="_blank"
      rel="noreferrer"
    >{`${process.env.REACT_APP_API_BASE}/${page}`}</a>
  );
}

export default LandingPage;

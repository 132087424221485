import React from "react";
import styled from "styled-components";

export type Props = {
  label: React.ReactNode;
  icon: JSX.Element;
  marginStyle?: string;
  textPaddingLeft?: string;
};

const IconLabel = ({
  label,
  icon,
  marginStyle = "0.5em",
  textPaddingLeft = "1em",
}: Props) => (
  <IconLabelContainer marginStyle={marginStyle}>
    {icon}{" "}
    <IconLabelText textPaddingLeft={textPaddingLeft}>{label}</IconLabelText>
  </IconLabelContainer>
);
const IconLabelContainer = styled.div<{ marginStyle: string }>`
  display: flex;
  align-items: center;
  margin: ${(p) => p.marginStyle};
`;
const IconLabelText = styled.div<{ textPaddingLeft: string }>`
  flex: 1;
  padding-left: ${(p) => p.textPaddingLeft};
  font-weight: 400;
`;

export default IconLabel;

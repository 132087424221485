import React, { useRef } from "react";
import useOnClickOutside from "common/helpers/useOnClickOutside";
import styled from "styled-components";
import Button from "components/Button/Button";

export type Props = {
    setMenuClosed():void;
    isOpen:boolean;
    handleLogOff():void;
}

export default function HoverMenu({setMenuClosed, handleLogOff, isOpen}:Props) {
  const ref = useRef(null);

//   const handleClickOutside = () => {
//     // Your custom logic here
//     console.log("clicked outside");
//   };



  useOnClickOutside(ref, setMenuClosed);

  if(!isOpen)
  return null;

  return (
    <Container ref={ref} >
      <Button onClick={() => {
          handleLogOff();
          setMenuClosed();
          
      }}>Log Out</Button>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 62px;
  right: 8px;
  color: #000;
  padding:1em;
  width: 200px;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;

import IconLabel from "components/IconLabel";
import React from "react";
import styled from "styled-components";
export type Props = {
  color?: "primary" | "green" | "red";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  isLarge?:boolean;
  isSecondary?:boolean;
};

const Button = ({
  color,
  onClick,
  className,
  style,
  children,
  disabled,
  icon,
  isLarge,
  isSecondary
}: Props) => {
  const inner = !!icon ? (
    <IconLabel
      icon={icon}
      label={children}
      marginStyle="0 .25em 0 0"
      textPaddingLeft=".5em"
      
    />
  ) : (
    children
  );
  if(isSecondary){
    return(<SecondaryBtn
      className={className}
      style={style}
      color="var(--primary-main)"
      onClick={onClick}
      disabled={disabled}
      isLarge={isLarge}
    >
      {inner}
    </SecondaryBtn>)
  }

  if (color === "green") {
    return (
      <Btn
        className={className}
        style={style}
        color="#02B004"
        hoverColor="#2e7d32"
        onClick={onClick}
        disabled={disabled}
        isLarge={isLarge}
      >
        {inner}
      </Btn>
    );
  } else if (color === "red") {
    return (
      <Btn
        className={className}
        style={style}
        color="#e44c4f"
        hoverColor="#921417"
        onClick={onClick}
        disabled={disabled}
        isLarge={isLarge}
      >
        {inner}
      </Btn>
    );
  }

  return (
    <Btn
      className={className}
      style={style}
      color="var(--primary-main)"
      hoverColor="var(--primary-main-hover)"
      onClick={onClick}
      disabled={disabled}
      isLarge={isLarge}
    >
      {inner}
    </Btn>
  );
};

export default Button;

const Btn = styled.button<{
  color: string;
  hoverColor: string;
  disabled?: boolean;
  isLarge?:boolean;
}>`
  color: #fff;
  background-color: ${(p) => (p.disabled ? p.hoverColor : p.color)};
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: ${p => p.isLarge ? '8px 20px' : '6px 16px'} ;
  font-size: ${p => p.isLarge ? '1rem' : '0.875rem'} ;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 3px;
  letter-spacing: 0.02857em;

  border: 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  &:hover {
    background-color: ${(p) => p.hoverColor};
  }
`;


const SecondaryBtn = styled.button<{
  disabled?: boolean;
  isLarge?:boolean;
}>`
  color: var(--primary-main);
  background-color: ${(p) => (p.disabled ? 'red' : '#fff')};

  padding: ${p => p.isLarge ? '8px 20px' : '6px 16px'} ;
  font-size: ${p => p.isLarge ? '1rem' : '0.875rem'} ;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  border-radius: 3px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 0;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  border: 2px solid #0090C9;
  &:hover {
    text-decoration:underline;
  }
`

//background: transparent; border-color: #00A0DF; color: #00A0DF;
import{ useState } from "react";
import Button from "components/Button/Button";
import IconButton from "components/IconButton/IconButton";
import { accountCircleIcon } from "common/icons";
import HoverMenu from "./HoverMenu";
export type Props = {
  isAuthenticated: boolean;
  onLogInClick(): void;
  onLogOffClick(): void;
};

const AccountButton = ({
  onLogInClick,
  onLogOffClick,
  isAuthenticated,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  if (isAuthenticated) {
    return (
      <div>
        <IconButton whitehoverback onClick={() => setMenuOpen(true)}>
          {accountCircleIcon}
        </IconButton>
        <HoverMenu
          isOpen={isMenuOpen}
          setMenuClosed={() => setMenuOpen(false)}
          handleLogOff={onLogOffClick}
        />
      </div>
    );
  }

  return (
    <Button onClick={onLogInClick}>
      {/* <IconLabel
        icon={icons.accountCircleIcon}
        label="Login"
        marginStyle="0 .25em 0 0"
      /> */}
      <span className="i-lock"></span>&nbsp;&nbsp;Login
    </Button>
  );
};

export default AccountButton;

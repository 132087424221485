import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "monerisCheckout";

type DetailItem = {
  price: number;
  name: string;
  vervoeAssessmentSlug: string;
};

type DetailsData = {
  cartItems: DetailItem[];
  subTotal: number;
  taxes: number;
  total: number;
};

var loadDetails = createAsyncAction<
  DetailsData,
  {
    slugs: string[];
  },
  State
>({
  actionPrefix: controlName,
  actionName: "loadDetails",
  url: `${process.env.REACT_APP_API_BASE}/MonerisPayment/LoadCartDetailsForPayment`,
  pending: (state, action) => {
    state.cartDetails = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.cartDetails = {
      isLoading: false,
      data: action.payload,
    };
  },
  rejected: (state, action) => {
    state.cartDetails = {
      isLoading: false,
      error: action.payload,
    };
  },
  getAuthBearerToken
});

type State = {
  cartDetails: ReducerState<DetailsData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    cartDetails: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadDetails.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadDetails: loadDetails.action,
    },
  },
};

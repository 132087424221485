import shoppingCart from "./ShoppingCart/shoppingCart.duck";
import checkout from "./Checkout/checkout.duck";

import myAssessments from "./MyCredentials/myCredentials.duck";
import credentialPage from "./CredentialPage/credentialPage.duck";

import preSelfAssessment from "./SelfAssessment/PreSelfAssessment/preSelfAssessment.duck";
import selfAssessmentResult from "./SelfAssessment/SelfAssessmentResult/selfAssessmentResult.duck";

import monerisCheckout from "./Checkout/MonerisCheckout/monerisCheckout.duck";

import admin from "./Admin/admin.duck";

export default {
  reducer: {
    ...shoppingCart.reducer,
    ...checkout.reducer,
    ...myAssessments.reducer,
    ...admin.reducer,
    ...credentialPage.reducer,
    ...preSelfAssessment.reducer,
    ...selfAssessmentResult.reducer,
    ...monerisCheckout.reducer,
  },
  actions: {
    ...shoppingCart.actions,
    ...checkout.actions,
    ...myAssessments.actions,
    ...admin.actions,
    ...credentialPage.actions,
    ...preSelfAssessment.actions,
    ...selfAssessmentResult.actions,
    ...monerisCheckout.actions,
  },
};

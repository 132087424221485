import styled from "styled-components";

export const MEDIA_QUERIES = {
  desktop: "@media(min-width: 1100px)",
  tablet: "@media(min-width: 780px)",
};

export const MarginSection = styled.div`
  color: var(--font-foreground);
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1em;

  ${MEDIA_QUERIES.tablet} {
    padding: 0 2rem;
  } ;
`;

export const FlexGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 2em;
  ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
    margin-right: 0;
  }
`;
export const H1 = styled.h1`
  color: var(--font-foreground);
  & > span {
    color: #b78de4;
  }
  padding-bottom: 1.5rem;
`;
export const Spacer1 = styled.div`
  height: 1rem;
`;
export const Spacer3 = styled.div`
  height: 3rem;
`;
export const Spacer6 = styled.div`
  height: 3rem;
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncAction,
} from "@nait-aits/redux";
import { ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "shoppingCart";

type ShoppingCartItem = {
  description: string;
  price: number;
  name: string;
  programName: string;
  vervoeAssessmentSlug: string;
};

type CartDetailsData = {
  cartItems: ShoppingCartItem[];
  recommendedItems: ShoppingCartItem[];
};

type State = {
  cartDetails: ReducerState<CartDetailsData>;
  cartSlugs: string[];
  // recommendedItemSlugs: string[];
  // shoppingCart: ReducerState<ShoppingCartItem[]>
};

var loadShoppingCartItems = createAsyncAction<
  CartDetailsData,
  { slugs: string[] },
  State
>({
  getAuthBearerToken,
  actionPrefix: controlName,
  actionName: "loadShoppingCartItems",
  url: `${process.env.REACT_APP_API_BASE}/ShoppingCart/LoadCartDetails2`,
  pending: (state, action) => {
    state.cartDetails = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.cartDetails.isLoading = false;
    state.cartDetails.data = action.payload;
  },
  rejected: (state, action) => {
    state.cartDetails.isLoading = false;
    state.cartDetails.error = action.payload;
  },
});

const localStorageItem = localStorage["cartSlugs"]
  ? JSON.parse(localStorage["cartSlugs"])
  : undefined;

var slice = createSlice({
  name: controlName,
  initialState: {
    cartDetails: {
      isLoading: false,
    },
    cartSlugs: localStorageItem ? localStorageItem : [],
  } as State,
  reducers: {
    addItemToCart: (state, action: PayloadAction<string>) => {
      if (!state.cartSlugs.find((e) => e === action.payload)) {
        state.cartSlugs.push(action.payload);
      }

      localStorage.setItem("cartSlugs", JSON.stringify(state.cartSlugs));
    },
    removeItemFromCart: (state, action: PayloadAction<string>) => {
      var found = state.cartSlugs.indexOf(action.payload);
      if (found >= 0) {
        var tmp = [...state.cartSlugs];
        tmp.splice(found, 1);
        state.cartSlugs = tmp;
      }

      localStorage.setItem("cartSlugs", JSON.stringify(state.cartSlugs));
    },
    clearCart: (state) => {
      state.cartSlugs = [];

      localStorage.setItem("cartSlugs", JSON.stringify(state.cartSlugs));
    },
  },
  extraReducers: {
    ...loadShoppingCartItems.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      // getAssessmentDetails: getAssessmentDetails.action,
      loadShoppingCartItems: loadShoppingCartItems.action,
    },
  },
};

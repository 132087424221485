import React, { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import RightAppBarContent from "pages/templates/MainPageTemplate/components/RightAppBarContent";
import MainPageTemplate from "pages/templates/MainPageTemplate/MainPageTemplate";
import styled from "styled-components";
import { SimpleDialog } from "@nait-aits/ui";
import Button from "components/Button/Button";
import { useIsAuthenticated } from "@azure/msal-react";
import ErrorBox from "common/errorHandling/errorBox/ErrorBox";

export type Props = {};

const UserAssessments = (props: Props) => {
  const state = useReduxState((e) => e.admin.userAssessments);
  const psoftState = useReduxState((e) => e.admin.setPeopleSoftComplete);
  const actions = useActions().admin;

  const [selectedItem, setSelecteditem] = useState<{
    id: number;
    assessmentUuid: string;
    vervoeAssessmentSlug: string;
    userName: string;
    userId: number;
    isSelfAssessment: boolean;
    candidateUrl: string;
    workflow?: {
      Assessment: {
        Status: string;
      };
      AssessmentGrading: {
        Status: string;
      };
      CredentialEntry: {
        Status: string;
      };
    };
  }>();

  const [userNameFilter, setUserNameFilter] = useState("");
  const [aTypeFilter, setATypeFilter] = useState("all");

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      actions.loadUserAssessments({});
    }
    // console.log('load', isAuthenticated.toString());
  }, [actions, isAuthenticated]);

  var assessments = state.data ? state.data.assessments : [];

  if (userNameFilter) {
    assessments = assessments.filter((e) => e.userName === userNameFilter);
  }

  if (aTypeFilter === "sa") {
    assessments = assessments.filter((e) => e.isSelfAssessment);
  } else if (aTypeFilter === "nosa") {
    assessments = assessments.filter((e) => !e.isSelfAssessment);
  }

  return (
    <MainPageTemplate
      appBarRightContent={<RightAppBarContent />}
      hideFeatureBar={false}
      responsiveBody={true}
      isLoading={state.isLoading}
      body={
        <>
          {state.error && <ErrorBox error={state.error} />}
          {!state.isLoading && state.data && (
            <div>
              {selectedItem && (
                <div>
                  <SimpleDialog
                    handleBackgroundClick={() => {
                      setSelecteditem(undefined);
                    }}
                  >
                    <div style={{ padding: 20 }}>
                      <div style={{ margin: 10 }}>
                        <h2>
                          {selectedItem.isSelfAssessment
                            ? "Self Evaluation"
                            : "Direct Credential"}
                        </h2>
                      </div>
                      <div style={{ margin: 10 }}>
                        <b>UserName</b>: {selectedItem.userName}
                      </div>
                      <div style={{ margin: 10 }}>
                        <b>Assessment Id</b>: {selectedItem.id}
                      </div>
                      <div style={{ margin: 10 }}>
                        <b>Assessment Slug</b>:{" "}
                        {selectedItem.vervoeAssessmentSlug}
                      </div>
                      <div style={{ margin: 10 }}>
                        <b>Candidate Url</b>:{" "}
                        <a
                          href={selectedItem.candidateUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Candidate URL Link
                        </a>
                      </div>
                      {!selectedItem.isSelfAssessment && selectedItem.workflow && (
                        <div>
                          <div style={{ margin: 10 }}>
                            <b>Assessment Status</b>:
                            {selectedItem.workflow.Assessment.Status}
                          </div>
                          <div style={{ margin: 10 }}>
                            <b>Grading Status</b>:
                            {selectedItem.workflow.AssessmentGrading.Status
                              ? selectedItem.workflow.AssessmentGrading.Status
                              : "NA"}
                          </div>
                          <div style={{ margin: 10 }}>
                            <b>Credential Entry Status</b>:
                            {selectedItem.workflow.CredentialEntry.Status
                              ? selectedItem.workflow.CredentialEntry.Status
                              : "NA"}
                          </div>
                          {selectedItem.workflow.CredentialEntry.Status ===
                            "in progress" && (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                disabled={psoftState.isLoading}
                                color="green"
                                onClick={() => {
                                  // eslint-disable-next-line no-restricted-globals
                                  if (confirm("Are you sure?")) {
                                    actions.setPeopleSoftComplete({
                                      clientAssessmentId: selectedItem.id,
                                      callback: (workflow) => {
                                        setTimeout(() => {
                                          setSelecteditem({
                                            ...selectedItem,
                                            workflow,
                                          });
                                        }, 500);
                                      },
                                    });
                                  }
                                }}
                              >
                                Set as Entered in PeopleSoft
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                      <div style={{ margin: 20, display: "flex" }}>
                        <div style={{ margin: 20 }}>
                          <Button
                            onClick={() => {
                              if (selectedItem.isSelfAssessment) {
                                window.open(
                                  `/selfassessment/result/${selectedItem.id}`
                                );
                              } else {
                                window.open(`/my/${selectedItem.id}`);
                              }
                            }}
                          >
                            View Assessment
                          </Button>
                        </div>
                        <div style={{ margin: 20 }}>
                          <Button
                            onClick={() => {
                              // eslint-disable-next-line no-restricted-globals
                              if (confirm("Are you sure?")) {
                                actions.archiveUserAssessment({
                                  id: selectedItem.id,
                                  callback: () => {
                                    setTimeout(() => {
                                      setSelecteditem(undefined);
                                    }, 500);
                                  },
                                });
                              }
                            }}
                          >
                            Archive Assessment
                          </Button>
                        </div>
                      </div>
                    </div>
                  </SimpleDialog>
                </div>
              )}
              <div
                style={{
                  padding: 20,
                  backgroundColor: "white",
                  borderRadius: 5,
                  margin: 10,
                  width: 300,
                }}
              >
                <div style={{ display: "flex", padding: 5 }}>
                  <div style={{ margin: "auto" }}>UserName: </div>
                  <div>
                    <select
                      style={{ padding: 10 }}
                      value={userNameFilter}
                      onChange={(e) => {
                        setUserNameFilter(e.target.value);
                      }}
                    >
                      <option></option>
                      {state.data.allUsers.map((e, i) => (
                        <option key={e.userName + i}>{e.userName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div style={{ display: "flex", padding: 5 }}>
                  <div style={{ margin: "auto" }}>Assessment Type: </div>
                  <div>
                    <select
                      style={{ padding: 10 }}
                      value={aTypeFilter}
                      onChange={(e) => {
                        setATypeFilter(e.target.value);
                      }}
                    >
                      <option value="sa">Self Evaluation</option>
                      <option value="nosa">Direct Credential</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                {assessments
                  .filter(
                    (e) => !userNameFilter || userNameFilter === e.userName
                  )
                  .sort((a, b) => (a.id > b.id ? 1 : -1))
                  .map((e) => (
                    <div key={e.id}>
                      <AssessmentItem
                        item={e}
                        onSelected={() => {
                          setSelecteditem(e);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

type AssessmentItemProps = {
  item: {
    id: number;
    assessmentUuid: string;
    vervoeAssessmentSlug: string;
    userName: string;
    userId: number;
    isSelfAssessment: boolean;
    candidateUrl: string;
  };
  onSelected?(): void;
};

const AssessmentItem = (props: AssessmentItemProps) => {
  const { item, onSelected } = props;

  return (
    <AItemRow
      onClick={() => {
        onSelected?.();
      }}
      title={item.isSelfAssessment ? "Self Evaluation" : "Direct Credential"}
    >
      <div style={{ width: 60, marginRight: 10 }}>{item.id}</div>
      <div style={{ width: 200, marginRight: 10 }}>{item.userName}</div>
      <div style={{ width: "100%" }}>{item.vervoeAssessmentSlug}</div>
      <div style={{ width: 50 }}>{item.isSelfAssessment ? "SA" : ""}</div>
    </AItemRow>
  );
};

const AItemRow = styled.div`
  display: flex;
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export default UserAssessments;

import React from "react";
import Button from "components/Button/Button";
export type Props = {
    count:number;
    onClick:React.MouseEventHandler<HTMLButtonElement>
};
const ShoppingCartButton = ({count, onClick}: Props) => {

  return (
    <Button  onClick={onClick} ><span className="i-cart"></span>&nbsp;&nbsp;{count} {count === 1 ? 'Item' : 'Items'}</Button>
  );
};

export default ShoppingCartButton;
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "@nait-aits/redux";

import { ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "myCredentials";

type MyCredentialsData = {
  credentialItems: {
    name: string;
    programName: string;
    percentComplete?: number;
    assessmentId: number;
    assessmentUrl?: string;
    isSelfAssessment: boolean;
    assessmentStatus?: string;
    gradingStatus?: string;
    credentialStatus?: string;
  }[];
};

var loadMyCredentials = createAsyncAction<
  MyCredentialsData,
  undefined,
  State
>({
  actionPrefix: controlName,
  actionName: "loadMyCredentials",
  url: `${process.env.REACT_APP_API_BASE}/Account/MyAssessments`,
  pending: (state, action) => {
    state.myCredentials = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.myCredentials.isLoading = false;
    state.myCredentials.data = action.payload;
  },
  rejected: (state, action) => {
    state.myCredentials.isLoading = false;
    state.myCredentials.error = action.payload;
  },
  getAuthBearerToken
});

type State = {
  myCredentials: ReducerState<MyCredentialsData>;
};

var slice = createSlice<State, {}>({
  name: controlName,
  initialState: {
    myCredentials: {
      isLoading: false,
    },
  },
  reducers: {},
  extraReducers: {
    ...loadMyCredentials.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadMyCredentials: loadMyCredentials.action,
    },
  },
};

import React from "react";
import { useReduxState } from "store";
import styled from "styled-components";
import MSALAccountButton from "components/AccountButton/MSALAccountButton";
import ShoppingCartButton from "components/ShoppingCartButton/ShoppingCartButton";
import { useHistory } from "react-router-dom";

export type Props = {
  hideAccountButton?: boolean;
  hideShoppingCartButton?: boolean;
};

const RightAppBarContent = ({
  hideAccountButton = false,
  hideShoppingCartButton = false,
}: Props) => {
  const shoppingCartState = useReduxState((e) => e.shoppingCart);
  const history = useHistory();

  return (
    <RightContentContainer>
      {!hideShoppingCartButton && (
        <>
          <Spacer1 />
          <ShoppingCartButton
            count={shoppingCartState.cartSlugs.length}
            onClick={() => {
              history.push("/cart");
            }}
          />
        </>
      )}
      {!hideAccountButton && (
        <>
          <Spacer1 />
          <MSALAccountButton />
        </>
      )}
    </RightContentContainer>
  );
};

const Spacer1 = styled.div`
  width: 1em;
`;

const RightContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default RightAppBarContent;

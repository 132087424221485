import { MEDIA_QUERIES } from "common/styling/globals";
import styled from "styled-components";
export const ResponsiveBody = styled.div<{maxWidth?:600 | 800 | 1000}>`

  margin: 90px  0;
  ${MEDIA_QUERIES.tablet} {
    width: ${p => p.maxWidth && p.maxWidth < 800 ? p.maxWidth : 800 }px;
    margin: 150px  auto;
  }
  ${MEDIA_QUERIES.desktop} {
    width: ${p => p.maxWidth ? p.maxWidth : 1000 }px;
    margin: 150px  auto;
  }
`;
import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "credentialPage";

export type LoadCredentialWorkflowType = {
  assessment: {
    questions: string;
    timeToComplete: string;
    videoCameraRequired: boolean;
    description?: string;
  };
  marking: {
    timeToComplete: string;
    description?: string;
  };
  credentialEntry: {
    timeToComplete: string;
    description?: string;
  };
};

export type LoadCredentialStatusesType = {
  assessmentSubmissionStatus?: "notStarted" | "in progress" | "complete";
  assessmentGradingStatus?: "notStarted" | "in progress" | "complete" | "rejected" | "hired";
  credentialStatus?: "notStarted" | "in progress" | "complete";
};

export type LoadCredentialData = {
  workflow: LoadCredentialWorkflowType;
  name: string;
  description?: string;
  candidateUrl: string;
  statuses: LoadCredentialStatusesType;
};

var loadCredential = createAsyncAction<
  LoadCredentialData,
  { id: string },
  State
>({
  getAuthBearerToken,
  actionPrefix: controlName,
  actionName: "loadCredential",
  url: `${process.env.REACT_APP_API_BASE}/Account/MyCredential`,
  pending: (state, action) => {
    state.credential = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.credential.isLoading = false;
    state.credential.data = action.payload;
  },
  rejected: (state, action) => {
    state.credential.isLoading = false;
    state.credential.error = action.payload;
  },
});

/* tslint: asdasd */
type State = {
  credential: ReducerState<LoadCredentialData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    credential: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadCredential.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadCredential: loadCredential.action,
    },
  },
};

import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
} from "@nait-aits/redux";

import { ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "checkout";

type CreateOrderTransactionData = {
  transactionId: string;
  orderAmount: number;
};

var createOrderTransaction = createAsyncAction<
  CreateOrderTransactionData,
  {
    slugs: string[];
  },
  State
>({
  actionPrefix: controlName,
  actionName: "createOrderTransaction",
  url: `${process.env.REACT_APP_API_BASE}/ShoppingCart/CreateOrderTransaction`,
  pending: (state, action) => {
    state.orderTransaction.isLoading = true;
    state.orderTransaction.data = undefined;
  },
  fulfilled: (state, action) => {
    state.orderTransaction.isLoading = false;
    state.orderTransaction.data = action.payload;
  },
  rejected: (state, action) => {
    state.orderTransaction.isLoading = false;
    state.orderTransaction.error = action.payload;
  },
  getAuthBearerToken
});

type MakePaymentData = {
  paymentId: string;
};

var makePayment = createAsyncAction<
  MakePaymentData,
  {
    amount: number;
    orderTransactionId: string;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "makePayment",
  url: `${process.env.REACT_APP_API_BASE}/ShoppingCart/MakePayment_Temporary`,
  pending: (state, action) => {
    state.makePayment.isLoading = true;
    state.makePayment.data = undefined;
  },
  fulfilled: (state, action) => {
    state.makePayment.isLoading = false;
    state.makePayment.data = action.payload;
  },
  rejected: (state, action) => {
    state.makePayment.isLoading = false;
    state.makePayment.error = action.payload;
  },
  getAuthBearerToken
});

type ProcessPaymentData = {
  orderId: number;
};

var processPayment = createAsyncAction<
  ProcessPaymentData,
  {
    orderTransactionId: string;
    paymentId: string;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "processPayment",
  url: `${process.env.REACT_APP_API_BASE}/ShoppingCart/ProcessPayment`,
  pending: (state, action) => {
    state.processPayment.isLoading = true;
    state.processPayment.data = undefined;
  },
  fulfilled: (state, action) => {
    state.processPayment.isLoading = false;
    state.processPayment.data = action.payload;
  },
  rejected: (state, action) => {
    state.processPayment.isLoading = false;
    state.processPayment.error = action.payload;
  },
  getAuthBearerToken
});

type State = {
  orderTransaction: ReducerState<CreateOrderTransactionData>;
  makePayment: ReducerState<MakePaymentData>;
  processPayment: ReducerState<ProcessPaymentData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    orderTransaction: {
      isLoading: false,
    },
    makePayment: {
      isLoading: false,
    },
    processPayment: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...createOrderTransaction.reducer,
    ...makePayment.reducer,
    ...processPayment.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      createOrderTransaction: createOrderTransaction.action,
      makePayment: makePayment.action,
      processPayment: processPayment.action,
    },
  },
};

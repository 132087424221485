import pages from 'pages/reducer';
import vervoe from 'common/providers/vervoe/vervoe.duck';
// import components from 'common/reducer';


const reducers = {
  ...pages.reducer,
  ...vervoe.reducer
//   ...components.reducer
};

const actions = {
  ...pages.actions,
  ...vervoe.actions
//   ...components.actions
};

export type ActionState = typeof actions;

const index = {
    reducers,
    actions,
  };

export default index;
 
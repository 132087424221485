import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";

import moment from "moment";
import MainPageTemplate from "pages/templates/MainPageTemplate/MainPageTemplate";
import RightAppBarContent from "pages/templates/MainPageTemplate/components/RightAppBarContent";
import { SimpleDialog } from "@nait-aits/ui";
import Button from "components/Button/Button";

export type Props = {
  id?: string;
};

const Logs = ({ id }: Props) => {
  const state = useReduxState((e) => e.admin.emailLogs);
  const actions = useActions().admin;

  const [viewData, setViewData] = useState<string>();

  useEffect(() => {
    actions.loadEmailLogs({});
  }, [actions]);

  return (
    <MainPageTemplate
      appBarRightContent={<RightAppBarContent />}
      body={
        <>
          {state.isLoading && <div>Loading...</div>}
          {!state.isLoading && state.data && (
            <div>
              <div>
                Take:{" "}
                <select
                  style={{ padding: 10 }}
                  value={state.data.take.toString() ?? "20"}
                  onChange={(e) => {
                    actions.loadEmailLogs({ take: +e.target.value });
                  }}
                >
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>{" "}
                <Button
                  onClick={() => {
                    actions.loadEmailLogs({ take: state.data?.take });
                  }}
                >
                  Reload
                </Button>
              </div>
              <div>Logs</div>
              {state.data.items.map((item) => (
                <div
                  key={item.id}
                  style={{
                    margin: 5,
                    padding: 10,
                    border: "1px solid black",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ margin: 5 }}>Id: {item.id}</div>
                  <div style={{ margin: 5 }}>
                    Date: {moment(item.date).format("MMM DD, YYYY HH:mm")}
                  </div>
                  <div style={{ margin: 5 }}>Type: {item.logType}</div>
                  <div style={{ margin: 5 }}>
                    JsonContent:
                    <pre>
                      <code>
                        {item.logDataJson &&
                          JSON.stringify(JSON.parse(item.logDataJson), null, 2)}
                      </code>
                    </pre>
                  </div>
                  {item.logType === "Email" && (
                    <div>
                      <Button
                        onClick={() => {
                          var tmp: {
                            messageHtml: string;
                          } = JSON.parse(item.logDataJson);

                          setViewData(tmp.messageHtml);
                        }}
                      >
                        View Message Html
                      </Button>
                    </div>
                  )}
                </div>
              ))}
              {viewData && (
                <SimpleDialog
                  handleBackgroundClick={() => {
                    setViewData(undefined);
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: viewData,
                    }}
                  ></p>
                </SimpleDialog>
              )}
            </div>
          )}
        </>
      }
    />
  );
};

export default Logs;

import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";

import moment from "moment";
import MainPageTemplate from "pages/templates/MainPageTemplate/MainPageTemplate";
import RightAppBarContent from "pages/templates/MainPageTemplate/components/RightAppBarContent";

export type Props = {
  id?: string;
};

const Logs = ({ id }: Props) => {
  const state = useReduxState((e) => e.admin.hookLogs);
  const actions = useActions().admin;

  const [filterText, setFilterText] = useState<number | undefined>();

  useEffect(() => {
    actions.loadHookLogs({ id });
  }, [actions, id]);

  return (
    <MainPageTemplate
      appBarRightContent={<RightAppBarContent />}
      body={
        <>
          {state.isLoading && <div>Loading...</div>}
          {!state.isLoading && state.data && (
            <div>
              <div>
                User Assessment Id Filter:{" "}
                <input
                  type="number"
                  style={{ width: 100, padding: 10 }}
                  value={filterText?.toString() || ""}
                  onChange={(e) => {
                    // var tmp = parseInt(e.target.value);
                    // if (isNaN(tmp)) {
                    //   return;
                    // }
                    setFilterText(parseInt(e.target.value));
                  }}
                />{" "}
                <button onClick={() => setFilterText(undefined)}>Clear</button>
              </div>
              <div>Logs</div>
              {state.data.items
                .filter((e) => !filterText || e.userAssessmentId === filterText)
                .map((item) => (
                  <div
                    key={item.id}
                    style={{
                      margin: 5,
                      padding: 10,
                      border: "1px solid black",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ margin: 5 }}>Id: {item.id}</div>
                    <div style={{ margin: 5 }}>
                      Date:{" "}
                      {moment(item.dateCalled).format("MMM DD, YYYY HH:mm")}
                    </div>
                    {item.userAssessmentId > 0 && (
                      <div style={{ margin: 5 }}>
                        User Assessment ID: {item.userAssessmentId}{" "}
                        <button
                          onClick={() => setFilterText(item.userAssessmentId)}
                        >
                          Filter
                        </button>{" "}
                        <button
                          onClick={() => {
                            window.open(`/my/${item.userAssessmentId}`);
                          }}
                        >
                          View
                        </button>
                      </div>
                    )}
                    <div style={{ margin: 5 }}>
                      JsonContent:
                      <pre>
                        <code>
                          {item.contentJson &&
                            JSON.stringify(
                              JSON.parse(item.contentJson),
                              null,
                              2
                            )}
                        </code>
                      </pre>
                    </div>
                    {true && (
                      <div>
                        <button
                          onClick={() =>
                            actions.resubmitLogItemJson({
                              id: item.id,
                              loadCandidateAssessmentReport: true,
                            })
                          }
                        >
                          Resubmit
                        </button>{" "}
                        <button
                          onClick={() =>
                            actions.resubmitLogItemJson({
                              id: item.id,
                              loadCandidateAssessmentReport: false,
                            })
                          }
                        >
                          Resubmit (Do Not Load CandidateAssessmentReport)
                        </button>
                      </div>
                    )}
                    {item.notProcessedMessage && (
                      <div style={{ margin: 5, fontWeight: "bold" }}>
                        Message:
                        {item.notProcessedMessage}
                      </div>
                    )}
                    {item.userAssessmentId <= 0 && (
                      <div style={{ margin: 5, fontWeight: "bold" }}>
                        User Assessment Not Found
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </>
      }
    />
  );
};

export default Logs;

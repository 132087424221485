import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "preSelfAssessment";

type AssessmentData = {
  name: string;
  description: string;
  selfAssessment: {
    questions: string;
    timeToComplete: string;
    description: string;
    videoCameraRequired: boolean;
  };
  directPaths: {
    name: string;
    skills: string[];
  }[];
  // coursePaths: {
  //   name: string;
  //   courseCode: string;
  //   skills: string[];
  // }[];
};

type UserSelfAssessmentData = {
  assessmentId: number;
};

var loadSelfAssessment = createAsyncAction<
  AssessmentData,
  { credentialName: string },
  State
>({
  actionPrefix: controlName,
  actionName: "loadSelfAssessment",
  url: `${process.env.REACT_APP_API_BASE}/SelfAssessment/GetProgram`,
  pending: (state, action) => {
    state.assessment = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    state.assessment.isLoading = false;
    state.assessment.data = action.payload;
  },
  rejected: (state, action) => {
    state.assessment.isLoading = false;
    state.assessment.error = action.payload;
  },
});

var createUserSelfAssessment = createAsyncAction<
  UserSelfAssessmentData,
  { credentialName: string },
  State
>({
  getAuthBearerToken,
  actionPrefix: controlName,
  actionName: "createUserSelfAssessment",
  url: `${process.env.REACT_APP_API_BASE}/SelfAssessment/CreateUserSelfAssessment`,
  pending: (state, action) => {
    state.userSelfAssessment = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    state.userSelfAssessment.isLoading = false;
    state.userSelfAssessment.data = action.payload;
  },
  rejected: (state, action) => {
    state.userSelfAssessment.isLoading = false;
    state.userSelfAssessment.error = action.payload;
  },
});

type State = {
  assessment: ReducerState<AssessmentData>;
  userSelfAssessment: ReducerState<UserSelfAssessmentData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    assessment: {
      isLoading: false,
    },
    userSelfAssessment: {
      isLoading: false,
    },
  } as State,
  reducers: {
    reset: (state) => {
      state.assessment = {
        isLoading: false,
      };
      state.userSelfAssessment = {
        isLoading: false,
      };
    },
  },
  extraReducers: {
    ...loadSelfAssessment.reducer,
    ...createUserSelfAssessment.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadSelfAssessment: loadSelfAssessment.action,
      createUserSelfAssessment: createUserSelfAssessment.action,
    },
  },
};

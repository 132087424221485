import { lazy, Suspense } from "react";

import { Provider } from "react-redux";
import store from "store";

import "./App.css";

import { Router, Switch, Route } from "react-router-dom";

import LandingPage from "pages/LandingPage";

import pageHistory from "pageHistory";

import Logs from "pages/Admin/Logs";
import EmailLogs from "pages/Admin/EmailLogs";

import PageWrapper from "components/PageWrapper/PageWrapper";
import UserAssessments from "pages/Admin/UserAssessments";

const CredentialPage = lazy(
  () => import("./pages/CredentialPage/CredentialPage")
);

const ShoppingCart = lazy(() => import("./pages/ShoppingCart/ShoppingCart"));

const Checkout = lazy(
  () => import("./pages/Checkout/MonerisCheckout/MonerisCheckout")
);

const MyCredentials = lazy(() => import("./pages/MyCredentials/MyCredentials"));
const PreSelfAssessment = lazy(
  () => import("pages/SelfAssessment/PreSelfAssessment/PreSelfAssessment")
);
const SelfAssessmentResult = lazy(
  () => import("pages/SelfAssessment/SelfAssessmentResult/SelfAssessmentResult")
);

const LoggedIn = lazy(() => import("pages/LoggedIn/LoggedIn"));

export default function App() {
  return (
    <div>
      <Provider store={store}>
        <Router history={pageHistory}>
          <div>
            <Switch>
              <Route
                path="/cart/:credentialName?"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Shopping Cart"}>
                        <ShoppingCart
                          credentialName={match.params.credentialName}
                        />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
              <Route
                path="/my/:id"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"View Credential"}>
                        <CredentialPage id={match.params.id} />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
              <Route path="/my">
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper
                    title={"My Direct Credentials"}
                    excludeBaseTitle={true}
                  >
                    <MyCredentials />
                  </PageWrapper>
                </Suspense>
              </Route>
              <Route path="/checkout">
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper title={"Checkout"}>
                    <Checkout />
                  </PageWrapper>
                </Suspense>
              </Route>
              <Route
                path="/logs/:id?"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Admin Logs"}>
                        <Logs id={match?.params.id} />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
               <Route
                path="/emaillogs"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Email Logs"}>
                        <EmailLogs />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
              <Route
                path="/userAssessments"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Admin - User Assessments"}>
                        <UserAssessments />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
              <Route
                path="/selfAssessment/result/:id"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Self Evaluation Result"}>
                        <SelfAssessmentResult id={parseInt(match.params.id)} />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />
              <Route
                path="/selfAssessment/:credentialName"
                render={({ match }) => {
                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PageWrapper title={"Self Evaluation"}>
                        <PreSelfAssessment
                          credentialName={match.params.credentialName}
                        />
                      </PageWrapper>
                    </Suspense>
                  );
                }}
              />

              <Route path="/LoggedIn">
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper title={"Logging In"}>
                    <LoggedIn />
                  </PageWrapper>
                </Suspense>
              </Route>

              <Route path="/Links">
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper title={"App Links"}>
                    <LandingPage />
                  </PageWrapper>
                </Suspense>
              </Route>

              <Route path="/">
                <Suspense fallback={<div>Loading...</div>}>
                  <PageWrapper title={"Self Evaluation"}>
                    <PreSelfAssessment credentialName={"qualitymanagement"} />
                  </PageWrapper>
                </Suspense>
              </Route>
            </Switch>
          </div>
        </Router>
      </Provider>
    </div>
  );
}

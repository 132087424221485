import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "selfAssessmentResult";

type RecommendationScoreItem = {
  name: string;
  scorePercentage: number;
};

export type LoadSelfAssessmentStatusesType = {
  assessmentSubmissionStatus?: "notStarted" | "in progress" | "complete";
  assessmentGradingStatus?:
    | "notStarted"
    | "in progress"
    | "complete"
    | "rejected"
    | "hired";
  credentialStatus?: "notStarted" | "in progress" | "complete";
};

export type LoadSelfAssessmentWorkflowType = {
  assessment: {
    questions: string;
    timeToComplete: string;
    videoCameraRequired: boolean;
    description?: string;
  };
  marking: {
    timeToComplete: string;
    description?: string;
  };
  credentialEntry: {
    timeToComplete: string;
    description?: string;
  };
};

export type LoadCredentialData = {
  workflow: LoadSelfAssessmentWorkflowType;
  name: string;
  description?: string;
  candidateUrl: string;
  statuses: LoadSelfAssessmentStatusesType;
};

export type RecommendationData = {
  strenthsAndWeaknesses: {
    strengths: RecommendationScoreItem[];
    weaknesses: RecommendationScoreItem[];
  };
  directPaths: {
    name: string;
    slug: string;
    score: number;
    price: number;
    isRecommended: boolean;
    skills: {
      name: string;
      score: number;
    }[];
    coursePaths: {
      name: string;
      courseCode: string;
      courseUrl: string;
      score: number;
      isRecommended: boolean;
      skills: {
        name: string;
        score: number;
      }[];
    }[];
  }[];
};

export type SelfAssessmentResultData = {
  programName: string;
  passingGrade: number;
  recommendations?: RecommendationData;
  userAssessment: LoadCredentialData;
};

var loadSelfAssessmentResult = createAsyncAction<
  SelfAssessmentResultData,
  { id: number | string },
  State
>({
  getAuthBearerToken,
  actionPrefix: controlName,
  actionName: "loadSelfAssessmentResult",
  url: `${process.env.REACT_APP_API_BASE}/account/MySelfAssessment`,
  pending: (state, action) => {
    state.selfAssessmentResult = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    state.selfAssessmentResult.isLoading = false;
    state.selfAssessmentResult.data = action.payload;
  },
  rejected: (state, action) => {
    state.selfAssessmentResult.isLoading = false;
    state.selfAssessmentResult.error = action.payload;
  },
});

type State = {
  selfAssessmentResult: ReducerState<SelfAssessmentResultData>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    selfAssessmentResult: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadSelfAssessmentResult.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadSelfAssessmentResult: loadSelfAssessmentResult.action,
    },
  },
};

import { AppBar } from "@nait-aits/ui";
import { ResponsiveBody } from "components/ResponsiveBody/ResponsiveBody";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export type Props = {
  appBarRightContent?: JSX.Element;
  appBarHeadingText?: string;
  body?: JSX.Element | string;
  backButtonText?: string;
  featureBarHeight?: number;
  hideFeatureBar?: boolean;
  responsiveBody?: boolean;
  backgroundColor?: string;
  appBarOnBackClick?(): void;
  isLoading?: boolean;
  featureBarColor?: "blue" | "lightBlue";
};

const MainPageTemplate = ({
  appBarRightContent,
  body,
  responsiveBody = true,
  appBarOnBackClick,
  isLoading = false,
  appBarHeadingText = "DIRECT PATHWAY",
  backButtonText = "Dashboard",
}: Props) => {
  const history = useHistory();
  
  return (
    <>
      <AppBar
        headingText={backButtonText && appBarOnBackClick ? backButtonText : appBarHeadingText}
        rightContent={appBarRightContent}
        onHeadingClick={
          appBarOnBackClick
            ? appBarOnBackClick
            : () => {
                history.push("/my");
              }
        }
        isBackButton={!!appBarOnBackClick}
        isLoading={isLoading}
      />
      <Page>
        {responsiveBody && <ResponsiveBody>{body}</ResponsiveBody>}
        {!responsiveBody && body}
      </Page>
    </>
  );
};

const Page = styled.div`
  grid-column: 1;
  grid-row: 1;
`;
export default MainPageTemplate;

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "@nait-aits/redux";

import { ReducerState } from "@nait-aits/redux";
import { getAuthBearerToken } from "store/getAuthBearerToken";

const controlName = "admin";

type HookLogsData = {
  items: {
    id: number;
    contentJson: string;
    dateCalled: Date;
    assessmentUuid: string;
    notProcessedMessage: string;
    processed: boolean;
    processedOn?: Date;
    userAssessmentId: number;
  }[];
};

var loadHookLogs = createAsyncAction<
  HookLogsData,
  { id?: string },
  State
>({
  actionPrefix: controlName,
  actionName: "getHookLogs",
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetHookLogs`,
  pending: (state, action) => {
    state.hookLogs = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.hookLogs.isLoading = false;
    state.hookLogs.data = action.payload;
  },
  rejected: (state, action) => {
    state.hookLogs.isLoading = false;
    state.hookLogs.error = action.payload;
  },
  getAuthBearerToken
});

type EmailLogsData = {
  take: number;
  skip: number;
  items: {
    id: number;
    logType: string;
    logDataJson: string;
    date: Date;
  }[];
};

var loadEmailLogs = createAsyncAction<
  EmailLogsData,
  { take?: number; skip?: number },
  State
>({
  actionPrefix: controlName,
  actionName: "loadEmailLogs",
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetLogs`,
  pending: (state, action) => {
    state.emailLogs = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.emailLogs.isLoading = false;
    state.emailLogs.data = action.payload;
  },
  rejected: (state, action) => {
    state.emailLogs.isLoading = false;
    state.emailLogs.error = action.payload;
  },
  getAuthBearerToken
});

type ResubmitLogItemJsonReturn = {};
type ResubmitLogItemJsonData = ReducerState<ResubmitLogItemJsonReturn>;

var resubmitLogItemJson = createAsyncAction<
  ResubmitLogItemJsonReturn, //return type
  { id: number; loadCandidateAssessmentReport: boolean }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "resubmitLogItemJson",
  url: `${process.env.REACT_APP_API_BASE}/Admin/ResubmitHookLogItemJson`,
  pending: (state, action) => {
    state.resubmitLogItemJsonItem = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.resubmitLogItemJsonItem.isLoading = false;
    state.resubmitLogItemJsonItem.data = action.payload;
  },
  rejected: (state, action) => {
    state.resubmitLogItemJsonItem.isLoading = false;
    state.resubmitLogItemJsonItem.error = action.payload;
  },
  getAuthBearerToken
});

type UserAssessementsData = {
  assessments: {
    id: number;
    assessmentUuid: string;
    vervoeAssessmentSlug: string;
    userName: string;
    userId: number;
    isSelfAssessment: boolean;
    candidateUrl: string;
    workflowJson: string;
    workflow?: {
      Assessment: {
        Status: string;
      };
      AssessmentGrading: {
        Status: string;
      };
      CredentialEntry: {
        Status: string;
      };
    };
  }[];
  allUsers: {
    userName: string;
    isAdmin: boolean;
    id: number;
  }[];
};

var loadUserAssessments = createAsyncAction<
  UserAssessementsData,
  {},
  State
>({
  actionPrefix: controlName,
  actionName: "loadUserAssessments",
  url: `${process.env.REACT_APP_API_BASE}/Admin/GetUserAssessments`,
  pending: (state, action) => {
    state.userAssessments = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    state.userAssessments.isLoading = false;
    state.userAssessments.data = action.payload;

    state.userAssessments?.data?.assessments?.forEach((element) => {
      if (element.workflowJson)
        element.workflow = JSON.parse(element.workflowJson);
    });
  },
  rejected: (state, action) => {
    state.userAssessments.isLoading = false;
    state.userAssessments.error = action.payload;
  },
  getAuthBearerToken
});

var archiveUserAssessment = createAsyncAction<
  { userId: number; userName: string },
  { id: number; callback?(): void },
  State
>({
  actionPrefix: controlName,
  actionName: "archiveUserAssessment",
  url: `${process.env.REACT_APP_API_BASE}/Admin/ArchiveUserAssessment`,
  pending: (state, action) => {
    state.archiveUserAssessment = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    state.archiveUserAssessment.isLoading = false;

    var assessment = state.userAssessments.data?.assessments.find(
      (e) => e.id === action.params.id
    );

    if (assessment) {
      assessment.userId = action.payload.userId;
      assessment.userName = action.payload.userName;

      if (state.userAssessments.data?.assessments) {
        state.userAssessments.data.assessments = [
          ...state.userAssessments.data?.assessments,
        ];
      }
    }

    action.params.callback?.();
  },
  rejected: (state, action) => {
    state.archiveUserAssessment.isLoading = false;
    state.archiveUserAssessment.error = action.payload;
  },
  getAuthBearerToken
});

var setPeopleSoftComplete = createAsyncAction<
  { workflowJson: string },
  {
    clientAssessmentId: number;
    callback?(workflow?: {
      Assessment: {
        Status: string;
      };
      AssessmentGrading: {
        Status: string;
      };
      CredentialEntry: {
        Status: string;
      };
    }): void;
  },
  State
>({
  actionPrefix: controlName,
  actionName: "setPeopleSoftComplete",
  url: `${process.env.REACT_APP_API_BASE}/Admin/setPeopleSoftComplete`,
  pending: (state, action) => {
    state.setPeopleSoftComplete = {
      isLoading: true,
      data: undefined,
      error: undefined,
    };
  },
  fulfilled: (state, action) => {
    var assessment = state.userAssessments.data?.assessments.find(
      (e) => e.id === action.params.clientAssessmentId
    );

    if (assessment) {
      assessment.workflowJson = action.payload.workflowJson;
      assessment.workflow = JSON.parse(action.payload.workflowJson);
    }

    if (state.userAssessments.data?.assessments) {
      state.userAssessments.data.assessments = [
        ...state.userAssessments.data?.assessments,
      ];
    }

    state.setPeopleSoftComplete.isLoading = false;

    action.params.callback?.(assessment?.workflow);
  },
  rejected: (state, action) => {
    state.setPeopleSoftComplete.isLoading = false;
    state.setPeopleSoftComplete.error = action.payload;
  },
  getAuthBearerToken
});

type State = {
  hookLogs: ReducerState<HookLogsData>;
  emailLogs: ReducerState<EmailLogsData>;
  resubmitLogItemJsonItem: ResubmitLogItemJsonData;
  userAssessments: ReducerState<UserAssessementsData>;
  archiveUserAssessment: ReducerState<{ userId: number; userName: string }>;
  setPeopleSoftComplete: ReducerState<{ workflowJson: string }>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    hookLogs: {
      isLoading: false,
    },
    emailLogs: {
      isLoading: false,
    },
    userAssessments: {
      isLoading: false,
    },
    setPeopleSoftComplete: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadHookLogs.reducer,
    ...resubmitLogItemJson.reducer,
    ...loadUserAssessments.reducer,
    ...archiveUserAssessment.reducer,
    ...loadEmailLogs.reducer,
    ...setPeopleSoftComplete.reducer,
  },
});

export default {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadHookLogs: loadHookLogs.action,
      resubmitLogItemJson: resubmitLogItemJson.action,
      loadUserAssessments: loadUserAssessments.action,
      archiveUserAssessment: archiveUserAssessment.action,
      loadEmailLogs: loadEmailLogs.action,
      setPeopleSoftComplete: setPeopleSoftComplete.action,
    },
  },
};
